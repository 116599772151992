const nav = document.querySelector('#nav');
const header = document.querySelector('#header');
const toggleNav = document.querySelector('#toggle-nav');
const menuItemHasChildren = document.querySelectorAll(
  '.menu-item-has-children'
);

let lastScrollTop = 0;

const openMenu = () => {
  toggleNav.setAttribute('aria-expanded', 'true');
  header.setAttribute('data-state', 'open');
  nav.setAttribute('data-state', 'open');
};

const closeMenu = () => {
  toggleNav.setAttribute('aria-expanded', 'false');
  header.setAttribute('data-state', 'close');
  nav.setAttribute('data-state', 'close');
};

const hideHeader = () => {
  header.classList.add('header--hide');
  closeMenu();
};

const showHeader = () => {
  header.classList.remove('header--hide');
};

toggleNav.addEventListener('click', () => {
  const isOpen = toggleNav.getAttribute('aria-expanded') === 'true';

  isOpen ? closeMenu() : openMenu();
});

window.addEventListener('scroll', () => {
  const st = window.pageYOffset;
  st >= 400
    ? (hideHeader(),
      st < lastScrollTop && header.classList.remove('header--hide'),
      (lastScrollTop = st <= 0 ? 0 : st))
    : showHeader();
});

menuItemHasChildren.forEach((item) => {
  item.setAttribute('aria-expanded', 'false');
  item.addEventListener('click', (e) => {
    if (window.innerWidth > 992) return;

    const isOpen = item.getAttribute('aria-expanded') === 'true';

    if (isOpen) {
      if (e.target.tagName.toLowerCase() === 'a') return;
      item.setAttribute('aria-expanded', 'false');
    } else {
      item.setAttribute('aria-expanded', 'true');
      e.preventDefault();
    }
  });
});
